var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(!_vm.checkIfPermission('view wallet', _vm.admin.permissions))?_c('div',{staticClass:"w-100 h-100 d-flex justify-center align-center"},[_c('h1',{staticClass:"error--text"},[_vm._v("You don't have access to this page")])]):_vm._e(),_c('div',[_c('h1',{staticClass:"mb-5"},[_vm._v("Bill History")]),_c('user-details-min',{attrs:{"userId":_vm.minUserId,"open":_vm.openMin},on:{"update:open":function($event){_vm.openMin=$event}}}),_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"label":"Active History","items":_vm.billsList},model:{value:(_vm.activeHistory),callback:function ($$v) {_vm.activeHistory=$$v},expression:"activeHistory"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search...","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-card',[_c('v-container',[_c('v-data-table',{attrs:{"loading":_vm.loadingTransaction,"headers":_vm.headers,"items":_vm.transactions,"footer-props":{
                itemsPerPageOptions: [5, 10, 15, 25, 50, 100]
              },"server-items-length":_vm.apiData.total},on:{"update:items-per-page":_vm.updateItemPerPage,"update:page":_vm.updatePage},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.createdAt).toDateString())+" "),_c('br'),_vm._v(" "+_vm._s(new Date(item.createdAt).toLocaleTimeString())+" ")]}},{key:"item.user",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium cursor-pointer primary--text text-capitalize d-block",on:{"click":function($event){return _vm.openMinDialog(item.user._id)}}},[_vm._v(" "+_vm._s(item.user.username)+" ")])]}},{key:"item.status",fn:function(ref){
              var item = ref.item;
return [_c('v-card',{staticClass:"text-capitalize text-center white--text pa-1",attrs:{"color":item.status == 'completed'
                      ? 'success'
                      : item.status == 'processing'
                      ? 'warning'
                      : 'error',"flat":"","rounded":"md","outlined":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"py-3 text-center"},[_c('v-img',{staticClass:"mx-auto",attrs:{"src":require("@/assets/img/undraw_empty.svg"),"width":"325","alt":"empty"}}),_c('h2',{staticClass:"mt-3"},[_vm._v("No previous bonus conversion")])],1)]},proxy:true}],null,true)})],1)],1)],1)],1)],1),_c('fund-details',{attrs:{"actionProgress":_vm.progress,"open":_vm.transactionOpen,"transaction":_vm.transaction},on:{"update:actionProgress":function($event){_vm.progress=$event},"update:action-progress":function($event){_vm.progress=$event},"update:open":function($event){_vm.transactionOpen=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }