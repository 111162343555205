<template>
  <v-container>
    <div
      v-if="!checkIfPermission('view wallet', admin.permissions)"
      class="w-100 h-100 d-flex justify-center align-center"
    >
      <h1 class="error--text">You don't have access to this page</h1>
    </div>
    <div>
      <h1 class="mb-5">Bill History</h1>
      <user-details-min :userId="minUserId" :open.sync="openMin" />
      <v-row>
        <v-col>
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                v-model="activeHistory"
                label="Active History"
                :items="billsList"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search..."
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card>
            <v-container>
              <v-data-table
                :loading="loadingTransaction"
                :headers="headers"
                :items="transactions"
                :footer-props="{
                  itemsPerPageOptions: [5, 10, 15, 25, 50, 100]
                }"
                @update:items-per-page="updateItemPerPage"
                @update:page="updatePage"
                :server-items-length="apiData.total"
              >
                <template v-slot:[`item.createdAt`]="{ item }">
                  {{ new Date(item.createdAt).toDateString() }} <br />
                  {{ new Date(item.createdAt).toLocaleTimeString() }}
                </template>

                <template v-slot:[`item.user`]="{ item }">
                  <span
                    class="font-weight-medium cursor-pointer primary--text text-capitalize d-block"
                    @click="openMinDialog(item.user._id)"
                  >
                    {{ item.user.username }}
                  </span>
                </template>

                <template v-slot:[`item.status`]="{ item }">
                  <v-card
                    :color="
                      item.status == 'completed'
                        ? 'success'
                        : item.status == 'processing'
                        ? 'warning'
                        : 'error'
                    "
                    flat
                    rounded="md"
                    outlined
                    class="text-capitalize text-center white--text pa-1"
                  >
                    {{ item.status }}
                  </v-card>
                </template>

                <template v-slot:no-data>
                  <div class="py-3 text-center">
                    <v-img
                      src="@/assets/img/undraw_empty.svg"
                      width="325"
                      class="mx-auto"
                      alt="empty"
                    />
                    <h2 class="mt-3">No previous bonus conversion</h2>
                  </div>
                </template>
              </v-data-table>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <fund-details
      :actionProgress.sync="progress"
      :open.sync="transactionOpen"
      :transaction="transaction"
    />
  </v-container>
</template>

<script>
import billApi from '../../../api/admin/bill'
import { mapState } from 'vuex'
import UserDetailsMin from '../../../components/userDetailsMin.vue'
import { numberWithCommas } from '../../../utils/helpers'
import _ from 'lodash'

export default {
  components: {
    UserDetailsMin
  },
  data: () => ({
    numberWithCommas,
    activeTab: null,
    openMin: false,
    minUserId: '',
    progress: 'starting',
    search: '',
    transactionOpen: false,
    dialogDelete: false,
    headers: [
      {
        text: 'Type',
        sortable: true,
        value: 'type'
      },
      {
        text: 'User',
        sortable: false,
        value: 'user'
      },
      {
        text: 'Amount',
        value: 'amount'
      },
      {
        text: 'Amount Changed',
        value: 'amountCharged'
      },
      {
        text: 'Package',
        value: 'package'
      },
      {
        text: 'Event On',
        value: 'createdAt'
      },
      {
        text: 'Status',
        value: 'status'
      }

      // {
      //   text: 'Bonus (NGN)',
      //   value: 'bonus'
      // },
      // {
      //   text: 'Token',
      //   value: 'token'
      // }
    ],
    transactions: [],
    transaction: {},
    perPage: 10,
    loadingTransaction: false,
    apiData: {},
    lastPage: 1,
    activeHistory: 'all',
    billsList: [
      {
        value: 'all',
        text: 'All'
      },
      {
        value: 'airtime',
        text: 'Airtime'
      },
      {
        value: 'data',
        text: 'Internet'
      },
      {
        value: 'electricity',
        text: 'Electricity'
      },
      {
        value: 'betting',
        text: 'Betting'
      },
      {
        value: 'cable',
        text: 'Cable'
      },
      {
        value: 'airtime-to-cash',
        text: 'Airtime to Cash'
      }
    ]
    // betting,cable,electricity,airtime,data,airtime_to_cash
  }),
  created() {
    this.getTransactions(0)
    this.debounceSearch = _.debounce(() => {
      this.getTransactions(0)
    }, 500)
  },
  watch: {
    search(newValue) {
      if (newValue === '') {
        this.getTransactions(0)
        return
      }
      this.debounceSearch()
    },
    activeHistory() {
      this.getTransactions(0)
    }
  },
  computed: {
    ...mapState({
      admin: (state) => state.admin.admin
    })
  },
  methods: {
    openMinDialog(id) {
      this.openMin = true
      this.minUserId = id
    },
    async searchData() {
      this.loadingTransaction = true

      const params = {
        populate: ['user', 'actionedBy'],
        limit: this.perPage,
        keyword: this.search,
        offset: length
      }

      if (this.activeHistory !== 'all') {
        params.type = this.activeHistory
      }

      const res = await billApi.data().getHistory(params)
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          error: true,
          status: true
        })
        return
      }

      this.transactions = res.data.data.data.map((history) => ({
        ...history,
        type: this.formatType(history.type),
        date: new Date(history.createdAt).toDateString(),
        amount: numberWithCommas(history.amount)
      }))

      this.apiData = res.data.data.meta
      this.loadingTransaction = false
    },
    updatePage(page) {
      if (this.lastPage - page > 2) {
        page = this.lastPage
      }
      this.lastPage = page
      this.getTransactions((page - 1) * this.perPage)
    },
    updateItemPerPage(count) {
      this.perPage = count
      this.getTransactions((this.lastPage - 1) * this.perPage)
    },
    async getTransactions(length) {
      this.loadingTransaction = true

      const params = {
        populate: ['user', 'actionedBy'],
        limit: this.perPage,
        offset: length
      }

      if (this.search !== '') {
        params.keyword = this.search
      }

      if (this.activeHistory !== 'all') {
        params.type = this.activeHistory
      }

      const res = await billApi.data().getHistory(params)

      this.transactions = res.data.data.data.map((history) => ({
        ...history,
        type: this.formatType(history.type),
        date: new Date(history.createdAt).toDateString(),
        amount: numberWithCommas(history.amount)
      }))

      this.apiData = res.data.data.meta
      this.loadingTransaction = false
    },
    formatType(type) {
      let t = type.replace(/-/g, ' ')
      t = t.charAt(0).toUpperCase() + t.slice(1, t.length)
      return t
    }
  }
}
</script>

<style></style>
